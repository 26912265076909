<template>
  <div class="front-page">
    <div class="front-page__headline">
      {{ headline }}
    </div>

    <div class="front-page__categories">
      <slot name="categories" />
    </div>

    <div class="front-page__content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: "FrontPage",

  props: {
    headline: {
      type: String,
      default: null,
    },

    subline: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.front-page__headline {
  @extend %text-excerpt;
}

.front-page__content {
  padding: 6rem 0 0 0;
}
</style>
